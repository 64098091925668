import React from "react"

import { Link } from "gatsby"
import DynamicImage from "../dynamic-image"
import CategoryBoxesCSS from "./category-boxes.module.scss"

const CategoryBoxes = props => {

  const boxes = [];

  var cssClass = CategoryBoxesCSS.largeBox;

  if(props.links.length > 3){
    cssClass = CategoryBoxesCSS.smallBox;
  }

  for(var i=0; i<props.links.length; i++){

    boxes.push(
      <Link to={props.links[i]} className={cssClass}>
        <DynamicImage alt="" filename={props.images[i]} />
        <h2 dangerouslySetInnerHTML={{__html: props.titles[i]}} />
        <button><p>Skatīt</p></button>
      </Link>
    )

  }

  return(

    <div>
      {boxes}
    </div>

  )

}

export default CategoryBoxes
