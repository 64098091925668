import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CategoryPageCSS from "../components/category/category-page.module.scss"
import DynamicImage from "../components/dynamic-image"
import MobileBackbutton from "../components/mobile-backbutton"
import CategoryTitle from "../components/category/category-title"
import CategoryBoxes from "../components/category/category-boxes"

const ProductPageTopmost = ({ data }) => {

  const content = data.dataJson

  return (

    <Layout>
      <SEO title={content.title} />
      <MobileBackbutton />
      <DynamicImage alt="" filename={content.banner} class={CategoryPageCSS.banner} />
      <CategoryTitle className={CategoryPageCSS.title} title={content.title} desc={content.desc} />

      <div className={CategoryPageCSS.boxes}>

        <div className={CategoryPageCSS.redbox}>
          <div>
            <h5 dangerouslySetInnerHTML={{__html: content.redTitle}} />
            <p dangerouslySetInnerHTML={{__html: content.redContent}} />
          </div>
        </div>

        <div className={CategoryPageCSS.whitebox}>
          <div>
            <h5 dangerouslySetInnerHTML={{__html: content.whiteTitle}} />
            <p dangerouslySetInnerHTML={{__html: content.whiteContent}} />
          </div>
        </div>

      </div>

      <CategoryTitle className={CategoryPageCSS.slogan} title={content.sloganTitle} desc={content.sloganText} />

      <CategoryBoxes links={content.categoryLinks} images={content.categoryIcons} titles={content.categoryTitles} />

    </Layout>

  )
}

export default ProductPageTopmost

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      desc
      banner
      redTitle
      redContent
      whiteTitle
      whiteContent
      sloganTitle
      sloganText
      categoryLinks
      categoryIcons
      categoryTitles
    }
  }
`
